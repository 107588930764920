import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ seriesData, labelsData }) => {
  const [graphData, setGraphData] = useState({});
  
  useEffect(() => {
    setGraphData(seriesData);
  }, [seriesData]);

  const chartData = useMemo(() => ({
    series: seriesData,
    options: {
      chart: {
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
        },
      },
      title: {
        text: 'MoM Patient Visits',
        align: 'center',
        offsetY: 5,
        fontWeight: "300",
        fontSize: '14px'
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '11px',
          colors: ['#2D6E00', '#000'],
        },
        background: {
          enabled: false,
        },
      },
      xaxis: {
        categories: labelsData,
      },
      legend: {
        show: true,
        position: 'bottom',
        offsetY: 5,
      },
      fill: {
        opacity: 1,
        colors: ['#A4B592', '#F6B467'] // Custom colors for the bars and line
      },
      colors: ['#A4B592', '#F6B467'], // Ensure colors match the series order
      series: seriesData.map((series, index) => ({
        ...series,
        dataLabels: {
          position: index === 0 ? 'top' : 'bottom', // Different positions for different series
        },
      })),
    }
  }), [graphData]);

  return (
    <div>
      <div id="chart" className="line-chart-report">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          width="100%"
          height="200px"
          className="chart-width"
        />
      </div>
    </div>
  );
};

export default ApexChart;
