import React, { useState, useEffect } from "react";
import { apiCalling } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import Pagination from "react-js-pagination";
import "react-tabs/style/react-tabs.css";
import csv from "../image/icons/csv.png";
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx';
function DoctorLoginReport() {
    const [hospitalArray, setHospitalArray] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageRange, setPageRange] = useState(5);
    const [siteId, setSiteId] = useState("")
    const [itemsPerPage] = useState(10);
    const [doctorLoginDetailsData, setDoctorLoginDetailsData] = useState([]);
    const [doctorLoginDetailsDataMappedArray, setDoctorLoginDetailsDataMappedArray] = useState([]);
    const [doctorLoginDetailsDataPagination, setdoctorLoginDetailsDataPagination] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [dropDownValueSet] = useState({
        "accessMethod": [{ "value": "all" }, { "value": "web" }, { "value": "app" }],
        "type": [{ "value": "all" }, { "value": "specific" }]
    });
    // const [searchValue, setSearchValue] = useState("");
    const [selectedAccessMethod, setSelectedAccessMethod] = useState("all");
    const [selectedType, setSelectedType] = useState("all");
    const [inputValue, setInputValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showList, setShowList] = useState(false);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState('');
    const [lastDayOfMonth, setLastDayOfMonth] = useState('');
    // useEffect(() => {
    //     // if (siteId) {
    //     //     setSearchValue("")
    //     //     // setFirstAndLastDayOfMonth()
    //     //     getHospitalDetails(siteId)
    //     // }
    // }, [selectedAccessMethod, selectedType, firstDayOfMonth, lastDayOfMonth]);
    useEffect(() => {
        if (!inputValue) {
            setDoctorLoginDetailsDataMappedArray([])
            setDoctorLoginDetailsData([])
            setdoctorLoginDetailsDataPagination([])
            setSelectedAccessMethod("all")
            setSelectedType("all")
            setSiteId("")
            setFirstAndLastDayOfMonth();
            setTotalPageCount(0)
        }
    }, [inputValue]);
    useEffect(() => {
        setSiteId("")
        setInputValue("")
        setFirstAndLastDayOfMonth();
        getSiteDetails()
    }, []);
    const setFirstAndLastDayOfMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // JavaScript months are 0-indexed
        const firstDay = new Date(year, month - 1, 1); // First day of the current month
        const lastDay = new Date(year, month, 0); // Last day of the current month

        // Format the dates to YYYY-MM-DD
        const formattedFirstDay = formatDateFirstAndLast(firstDay);
        const formattedLastDay = formatDateFirstAndLast(lastDay);

        // Set the formatted dates in the state
        setFirstDayOfMonth(formattedFirstDay);
        setLastDayOfMonth(formattedLastDay);
    };
    const formatDateFirstAndLast = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        return `${year}-${month}-${day}`;
    };
    const getSiteDetails = () => {

        const siteDetail = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getAllSiteDetails",
            }),
        };
        $(".loader").show();
        apiCalling(siteDetail).then((data) => {
            if (data.success === "1") {
                $(".loader").hide();
                setHospitalArray(data.siteData)
            } else {
                $(".loader").hide();
                setHospitalArray([])
            }
        }).catch()
    }
    const searchHandler = () => {
        if (siteId) {
            const formattedFirstDay = firstDayOfMonth ? formatDate(firstDayOfMonth) : "";
            const formattedLastDay = lastDayOfMonth ? formatDate(lastDayOfMonth) : "";
            //?as per Backend Instructed
            if (!firstDayOfMonth && lastDayOfMonth) {
                alert("Choose Both Date Or Leave Them Empty")
                return false
            }
            else if (firstDayOfMonth && !lastDayOfMonth) {
                alert("Choose Both Date Or Leave Them Empty")
                return false
            }
            if (firstDayOfMonth && lastDayOfMonth && formattedFirstDay > formattedLastDay) {
                alert("To Date must be greater than From Date");
                return false;
            }
            const apiJson = {
                method: "POST",
                body: JSON.stringify(
                    {
                        "functionName": "doctorLoginDetails",
                        "fromDate": firstDayOfMonth ? formatDate(firstDayOfMonth) : "",
                        "toDate": lastDayOfMonth ? formatDate(lastDayOfMonth) : "",
                        "accessMethod": selectedAccessMethod,
                        "type": selectedType,
                        "siteId": siteId,
                        "max": "",
                        "offset": ""
                    }
                ),
            };
            $(".loader").show();
            apiCalling(apiJson).then((data) => {
                if (data.success === "1") {
                    const temp = data.result && data.result.map((o, i) => ({
                        id: i + 1,
                        ...o
                    }))
                    setdoctorLoginDetailsDataPagination(data.result)
                    setDoctorLoginDetailsData(data.result)
                    updatePagination(data.result)
                    setDoctorLoginDetailsDataMappedArray(temp.slice(0, 10))
                    setTotalPageCount(data.totalCount)
                    $(".loader").hide();
                } else {
                    setDoctorLoginDetailsData([])
                    setTotalPageCount(0)
                    setDoctorLoginDetailsDataMappedArray([])
                    setdoctorLoginDetailsDataPagination([])
                    // alert(data.errorMessage);
                    $(".loader").hide();
                }
            }).catch()
        } else { alert("Please Choose A Association First ") }

    };
    const handleInputChange = (event) => {
        setInputValue(event.target.value)
        const filteredResult = hospitalArray.filter(item => {
            return item.siteName.toLowerCase().includes(event.target.value);
        });
        setFilteredData(filteredResult);
        setShowList(true)
    };
    const handleChange = (item) => {
        setInputValue(item.siteName)
        // getHospitalDetails(item.siteId)
        setSiteId(item.siteId)
        setFirstAndLastDayOfMonth()
        setSelectedAccessMethod("all")
        setSelectedType("all")
        setShowList(false);
    };
    function formatDate(timestamp) {
        if (timestamp) {
            const date = new Date(timestamp);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        }
    }
    function formatDateDayMonthYear(timestamp) {
        if (timestamp) {
            const date = new Date(timestamp);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            const formattedDate = `${day}-${month}-${year} `;
            return formattedDate;
        }
    }
    const handlePageChange = (pageNumber) => {
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = doctorLoginDetailsData.slice(indexOfFirstItem, indexOfLastItem);
        setDoctorLoginDetailsDataMappedArray(currentItems);
        setActivePage(pageNumber);
    };
    const updatePagination = (data) => {
        const pageCount = Math.ceil(data.length / itemsPerPage);
        setPageRange(Math.min(pageCount, 5)); // Update page range with minimum of initialPageRange and actual pageCount
        setTotalPageCount(pageCount);
        setActivePage(1); // Reset active page to 1
    };
    // const handleInputChangeOnType = (event) => {
    //     if (siteId) {
    //         // setSearchValue(event.target.value);
    //         const filteredResult = doctorLoginDetailsData.filter(item => {
    //             return item['Doctor Name'].toLowerCase().includes(event.target.value.toLowerCase());
    //         });
    //         updatePagination(filteredResult);
    //         setDoctorLoginDetailsDataMappedArray(filteredResult.slice(0, itemsPerPage));
    //         setdoctorLoginDetailsDataPagination(filteredResult)
    //     } else {
    //         alert("Please Choose A Site First")
    //         document.getElementById("siteId").focus()
    //     }
    // };
    const handleStartTimeChange = (date) => {
        setFirstDayOfMonth(date);
    };
    const handleEndTimeChange = (date) => {
        setLastDayOfMonth(date)
    };
    const fileDownload = () => {
        const apiJson = {
            method: "POST",
            body: JSON.stringify(
                {
                    "functionName": "doctorLoginDetailsExport",
                    "fromDate": firstDayOfMonth ? formatDate(firstDayOfMonth) : "",
                    "toDate": lastDayOfMonth ? formatDate(lastDayOfMonth) : "",
                    "accessMethod": selectedAccessMethod,
                    "type": selectedType,
                    "siteId": siteId
                }
            ),
        };
        $(".loader").show();
        apiCalling(apiJson).then((data) => {
            if (data.success === "1") {
                exportToExcel(data)
                $(".loader").hide();
            } else {
                alert("Selected Fields Have No Data Found")
                $(".loader").hide();
            }
        }).catch()
    };
    const exportToExcel = (data) => {
        if (data && data.result && data.result.length > 0) {
            const headings = ["Doctor Name", "Contact Number", "First Login Date", "Last Login Date", "Website Name", "Method of Access", "WebSite Url"];
            let filteredData = data && data.result && data.result.map(item => {
                const filteredItem = {};
                headings.forEach(heading => {
                    if (item.hasOwnProperty(heading)) {
                        if (heading === "First Login Date" || heading === "Last Login Date") {
                            filteredItem[heading] = formatDate(item[heading]);
                        } else {
                            filteredItem[heading] = item[heading];
                        }
                    }
                });
                return filteredItem;
            });

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const dataFile = new Blob([excelBuffer], { type: fileType });
            const url = URL.createObjectURL(dataFile);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'doctor_Login_Details' + fileExtension;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else { alert("Selected Field Value Is empty") }
    };


    return (
        <div className="purpleWrap bg-clr-wthHead">
            <section>
                <Header />
                <div className="loader"></div>
            </section>
            <div className="loader"></div>
            <div className="container mb-5">
                <div>
                    <ul className="breadcrum-adminMangmnt">
                        <li>
                            <a href="/admin/dashboardAdmin">Dashboard</a>
                        </li>
                        <li>
                            <a className="current">Doctor Login Report</a>
                        </li>
                    </ul>
                </div>
                <div className="head-hospMangmntList mb-0"> Doctor Login Report</div>
                <div className="addHspPage-Wrapper">
                    <div className="paginationSection orderm-paginationSection pagination-HspList w-100"></div>
                    <div className="tableHeader-HspMangemnt row pb-3 pt-md-3 pt-2 ml-0" style={{ height: 'auto' }}
                    >
                        <div className="col-lg-2  col-md-4 mt-2 div-padding-right">
                            <label className="mb-0 invisible-label">invisible</label>
                            <input
                                type="text"
                                id="siteId"
                                value={inputValue}
                                autoComplete="off"
                                onChange={handleInputChange}
                                placeholder="Association Name..."
                                className="hspInfofieldinput  col-md-12 w-100"
                            />
                            {showList && inputValue && (
                                <ul className="input-design-journal">
                                    {filteredData.map((item, index) => (
                                        <li className="py-0" key={index}>
                                            <button className="btn input-design-btn" onClick={(e) => handleChange(item)}>
                                                {item.siteName}
                                            </button>
                                        </li>
                                    ))}
                                </ul>)}
                        </div>
                        {/* <div className="selectField-HspMangmnt col-md-2 margin-0">
                            <label className="mb-0"></label>
                            <input
                                name="cmbHospitalName"
                                type="text"
                                autoComplete="off"
                                id="cmbHospitalName"
                                value={searchValue}
                                placeholder="Doctor Name Search"
                                className="hspInfofieldinput hspinput-mobdoctr col-md-12"
                                onChange={(e) => handleInputChangeOnType(e)}
                            ></input>
                        </div> */}
                        <div className=" col-lg-2  col-md-4 mt-2 div-padding-right">
                            <label className="mb-0">Access Method</label>
                            <select
                                value={selectedAccessMethod}
                                className="hspInfofieldinput col-md-12"
                                onChange={(e) => setSelectedAccessMethod(e.target.value)}
                            >
                                {dropDownValueSet.accessMethod.map((option, index) => (
                                    <option key={index} value={option.value}>{option.value && option.value.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                        <div className=" col-lg-2  col-md-4 mt-2 div-padding-right">
                            <label className="mb-0">Type</label>
                            <select
                                value={selectedType}
                                className="hspInfofieldinput col-md-12"
                                onChange={(e) => setSelectedType(e.target.value)}
                            >
                                {dropDownValueSet.type.map((option, index) => (
                                    <option key={index} value={option.value}>{option.value && option.value.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-2  col-md-4 mt-2 div-padding-right">
                            <label className="mb-0">Start Date</label>
                            <DatePicker
                                selected={firstDayOfMonth && firstDayOfMonth ? new Date(firstDayOfMonth) : null}
                                className="hspInfofieldinput rounded-0 col-md-12"
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown={true}
                                peekNextMonth
                                showYearDropdown
                                placeholderText="Start Date"
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                maxDate={new Date()}
                                onChange={handleStartTimeChange}
                            />
                        </div>
                        <div className="col-lg-2  col-md-4 mt-2 div-padding-right">
                            <label className="mb-0">End Date</label>
                            <DatePicker
                                selected={lastDayOfMonth && lastDayOfMonth ? new Date(lastDayOfMonth) : null}
                                onChange={handleEndTimeChange}
                                className="hspInfofieldinput rounded-0 col-md-12"
                                yearDropdownItemNumber={5}
                                scrollableYearDropdown={true}
                                placeholderText="End Date"
                                showYearDropdown
                                showMonthDropdown
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-lg-2  col-md-4 mt-md-2 mt-0 d-flex align-items-center div-padding-right">
                            {/* <label className="mb-0 invisible-label">Search</label> */}
                            <div className="search-btn-div d-flex align-items-center w-100">
                                <button
                                    className="searchbtn-hspMng search-btn-report"
                                    onClick={() => searchHandler()}
                                >
                                    Search
                                </button>
                                {siteId && doctorLoginDetailsDataMappedArray.length > 0 &&
                                    <img
                                        onClick={() => fileDownload()}
                                        src={csv}
                                        alt="csv"
                                        width="25"
                                        title="Export CSV"
                                        className="ml-2"
                                        style={{ cursor: 'pointer' }}
                                    />
                                }
                            </div>
                        </div>
                        {/* {siteId && doctorLoginDetailsDataMappedArray.length > 0 &&
                            <div className="col-lg-1 col-md-4">
                                <label className="mb-0 invisible-label">invisible</label>
                                <img
                                    onClick={() => fileDownload()}
                                    src={csv}
                                    alt="csv"
                                    width="25"
                                    title="Export CSV"
                                    className="mt-1"
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        } */}
                    </div>
                    <div>
                        <div className="loader"></div>
                        {totalPageCount !== 0 && (
                            <div className="paginationSection orderm-paginationSection pagination-HspList w-100">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsPerPage}
                                    totalItemsCount={doctorLoginDetailsDataPagination.length}
                                    pageRangeDisplayed={pageRange} // Adjust as needed
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    key={pageRange}
                                    linkClass="page-link"
                                />
                            </div>
                        )}
                        <div className="table-responsive">
                            {totalPageCount !== 0 ? (
                                <table className="table table-bordered-hsp table_custom">
                                    <thead>
                                        <tr>
                                            <th>Doctor Name </th>
                                            <th>Contact Number </th>
                                            <th>First Login Date</th>
                                            <th>Last Login Date</th>
                                            <th>Method of Access</th>
                                            <th> Website Name</th>
                                            <th>WebSite Url</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {doctorLoginDetailsDataMappedArray.map((data, index) => (
                                            <tr key={index} >
                                                <td>{data['Doctor Name']}</td>
                                                <td>
                                                    {data['Contact Number']}
                                                </td>
                                                <td>
                                                    {formatDateDayMonthYear(data['First Login Date'])}
                                                </td>
                                                <td>
                                                    {formatDateDayMonthYear(data['Last Login Date'])}
                                                </td>
                                                <td className="journl-des-txt">
                                                    {data['Method of Access']}
                                                </td>
                                                <td className="journl-des-txt">
                                                    {data['Website Name']}
                                                </td>
                                                <td className="journl-des-txt">
                                                    {data['WebSite Url']}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="nodata_found">No Data Found</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default DoctorLoginReport;
