import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import DatePicker from "react-datepicker";
import moment from "moment";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import DoctorTemplate from "../image/excel/DoctorDashboardDetails.xlsx"
import DoctorDashboardSearch from "./doctorDashboardListSearch.jsx"
import DoctorFilePopup from "./doctorDashboardFilePopup.jsx"
import DoctorExcelPopup from "./doctorDashboardExcelPopup.jsx"
import Edit from "../image/icons/edit.png";
import PurpleLogoImg from "../image/media/purple-health.png"

const DoctorPanel = () => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [doctorFileList, setDoctorFileList] = useState([])
  const [activePage, setActivePage] = useState(1);
  const [max, setMax] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [imageDeatils, setimageDeatils] = useState({
    AWSAccessKeyId: "",
    s3bucketAccessKey: "",
    region: "us-east-1",
    bucket: "",
    bucketFolder: "",
  })
  const [imagePathData, setImagePathData] = useState("");
  const [loading, setLoading] = useState(false);
  const [excelPopupFlag, setExcelPopupFlag] = useState(false)
  const [fileId, setFileId] = useState("")
  const [fileData, setFileData] = useState([])

  useEffect(() => {
    imageUploadHandler();
    fileList("", 0);
  }, [])

  const imageUploadHandler = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setimageDeatils((props) => ({
          ...props,
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        }))
        const bucketPath =
          data.configPathDetails[0].s3BucketRelianceAddressPath +
          "/" +
          data.configPathDetails[0].S3BucketMainFolder +
          "/" +
          "doctorDashboard" +
          "/";
        setImagePathData(bucketPath);
      }
    }).catch((error) => {
      toast.error("An error occurred while getting details");
    });
  };

  const fileList = (flag, offsetNo) => {
    if (flag !== "") {
      if (endDate && startDate && (endDate.valueOf() < startDate.valueOf())) {
        toast.error("To date is greater than or equal to from date.")
        return false
      }
      else if (!endDate && !startDate) {
        toast.error("Please select from date and to date.")
        return false
      }
    }
    // $(".loader").show();
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "dashboardFilesList",
        fromDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        toDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        offset: offsetNo,
        max: max
      })
    }
    awsAPIUrlcommon(postData).then((data) => {
      // $(".loader").hide();
      setLoading(false);
      if (data.success === "1") {
        setDoctorFileList(data.footerLinksData.result)
        setTotalCount(data.totalCount)
      } else {
        setDoctorFileList([])
        setTotalCount(0)
      }
    }).catch((error) => {
      // $(".loader").hide();
      setLoading(false);
      toast.error("An error occurred while getting details");
    });
  }

  const handlePageChange = (pageNumber) => {
    const offset = (pageNumber - 1) * max;
    setOffSet(offset)
    setActivePage(pageNumber);
    fileList("", offset)
  };

  const editFile = (data) => {
    setFileData(data)
    setFileId(data.id)
    setExcelPopupFlag(true)
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <ToastContainer />
      <Suspense fallback={<div></div>}>
        <section>
          <Header />
          {loading && <div className="loader d-block"></div>}
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a href="/admin/doctor-dashboard-list">Doctor Dashboard List</a>
                </li>
                <li>
                  <a className="current">Doctor Dashboard File List</a>
                </li>
              </ul>
            </div>
            
      {loading && <div className="loader d-block"></div>}
          {/* <span className="closepopupqual close-popup-top" id="txtCancel" onClick={() => { props.setDoctorFileFlag(false) }}>
            <img src={Close} alt="Close" className="img-fluid" width="20" />
          </span> */}
            <div className="HosptlMangemntTable">

              <div className="row mb-3">
                <div className="col-md-5 head-hospMangmntList mb-0">Upload File List</div>
                <div className="col-md-7 row pr-0 justify-content-end">
                  <div className="col-6 col-md-4 pr-0">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="hspInfofieldinput rounded pl-1 mb-0"
                      yearDropdownItemNumber={5}
                      scrollableYearDropdown={true}
                      placeholderText="From Date"
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="col-6 col-md-4 pr-0">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="hspInfofieldinput rounded pl-1 mb-0"
                      yearDropdownItemNumber={5}
                      scrollableYearDropdown={true}
                      placeholderText="To Date"
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd-MM-yyyy"
                      popperPlacement="top-end"
                    />
                  </div>
                  <button className="searchbtn-hspMng w-auto mt-2 mt-md-0 ml-3" type="button"
                    onClick={() => { fileList("search", 0) }} >Search</button>
                </div>
              </div>
              <hr></hr>
              <div className="table-responsive">
                {totalCount > 0 ?
                  <table className="table table-bordered-hsp table_custom">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th style={{ minWidth: "100px" }}>Date</th>
                        <th style={{ minWidth: "100px" }}>Company Logo</th>
                        <th style={{ minWidth: "100px" }}>Project Logo</th>
                        <th style={{ minWidth: "60px" }}>Purple Logo</th>
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th style={{ minWidth: "100px" }}>Contact Mob.</th>
                        <th style={{ minWidth: "40px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {doctorFileList.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <a href={imagePathData + data.file_name} download>{data.file_name}</a>
                          </td>
                          <td>{moment(data.created_on).format("DD-MM-YYYY")}</td>
                          <td>
                            <img src={imagePathData + data.top_right_logo} width={80} />
                          </td>
                          <td>
                            <img src={imagePathData + data.bottom_right_logo} width={80} />
                          </td>
                          <td>
                            {data.purple_logo_required && <img src={PurpleLogoImg} width={80} /> }
                          </td>
                          <td>{data.contact_person_name}</td>
                          <td>{data.contact_person_email}</td>
                          <td>{data.contact_person_phone}</td>
                          <td>
                            <button onClick={()=>editFile(data)} className="btn">
                            <img src={Edit} width={20}/>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  :
                  <p className="text-center py-5 mt-4">No data found</p>
                }
              </div>

              {totalCount > max &&
                <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={max}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              }
          </div>
          </div>
          {excelPopupFlag && <DoctorExcelPopup setExcelPopupFlag={setExcelPopupFlag}  fileId={fileId} setFileId={setFileId}
           fileData={fileData} setFileData={setFileData} fileList={fileList}/>}
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};

export default DoctorPanel;
