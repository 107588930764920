import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import $ from "jquery";
import csv from "../image/icons/csv.png";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast, ToastContainer } from "react-toastify";
import DoctorTemplate from "../image/excel/DoctorDashboardDetails.xlsx"
import DoctorDashboardSearch from "./doctorDashboardListSearch.jsx"
import DoctorFilePopup from "./doctorDashboardFilePopup.jsx"
import DoctorExcelPopup from "./doctorDashboardExcelPopup.jsx"

const DoctorPanel = () => {
  const navigate = useNavigate();
  const [siteId, setSiteId] = useState("")
  const [doctorName, setDoctorName] = useState("")
  const [siteName, setSiteName] = useState("")
  const [activePage, setActivePage] = useState(1);
  const [max, setMax] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [doctorList, setDoctorList] = useState([])
  const [doctorFileFlag, setDoctorFileFlag] = useState(false)
  const [showMessage, setShowMessage] = useState("Please select site name first.")
  const [excelPopupFlag, setExcelPopupFlag] = useState(false)
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState("")
  const [fileData, setFileData] = useState([])
  const [excelDoctorList, setExcelDoctorList] = useState([])

  useEffect(() => {
    siteId && getDoctorList()
  }, [siteId, offSet, max])

  const pdfDoctorDetails = (data) => {
    window.sessionStorage.setItem("doctorDashBId", data.doctorId)
    window.sessionStorage.setItem("doctorDashBName", data.displayName)
    navigate('/admin/doctor-report');
  }
  const handlePageChange = (pageNumber) => {
    const offset = (pageNumber - 1) * max;
    setOffSet(offset)
    setActivePage(pageNumber);
    // getDoctorList(offset)
  };

  const getDoctorList = () => {
    // $(".loader").show();
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: siteId,
        doctorName: doctorName,
        max: offSet,
        offset: max,
      }),
    }
    apiCalling(postData).then((data) => {
      if (data.success === "1") {
        setDoctorList(data.doctorData)
        setTotalCount(data.doctorDataCount)
        if (data.doctorData.length === 0) {
          setShowMessage("No data found.")
        }
      }
      else {
        setShowMessage("No data found.")
        setDoctorList([])
        setTotalCount(0)
      }
      // $(".loader").hide();
      setLoading(false);
    }).catch((error) => {
      // $(".loader").hide();
    setLoading(false);
      toast.error("An error occurred while Getting Details");
    });
  }
  const excelDownload = () => {
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: siteId,
        doctorName: doctorName,
        max: 0,
        offset: 10000,
      }),
    }
    apiCalling(postData).then((data) => {
      setLoading(false);
      if (data.success === "1") {
        setExcelDoctorList(data.doctorData)
        ExportAllDataExcel(data.doctorData)
      }
      else {
        toast.error("No data found.");
        setExcelDoctorList([])
      }
      // $(".loader").hide();
    }).catch((error) => {
      // $(".loader").hide();
      setLoading(false);
      toast.error("An error occurred while Getting Details");
    });
  }

  const ExportAllDataExcel = (exportData) => {
    if (exportData.length > 0) {
      const header = [
          "Doctor Id",
          "Doctor Name",
          "City",
          "Hospital Name",
          "Address",
          "Specialization",
          "Services",
      ];
      const rows = exportData.map((item) => [
          item.doctorId,
          item.displayName,
          item.city,
          item.hospitalName,
          item.address,
          item.specialization,
          item.services.join(", "), // Join services array as a string
      ]);
      const worksheetData = [header, ...rows];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const columnWidths = [
          { wch: 10 },  // doctorId
          { wch: 22 }, // displayName
          { wch: 20 }, // city
          { wch: 24 }, // hospitalName
          { wch: 35 }, // address
          { wch: 20 }, // specialization
          { wch: 40 }, // services
      ];
      worksheet['!cols'] = columnWidths;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const filename = 'doctor-list.xlsx';
      saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), filename);
  }
    else {
      toast.error("Doctor List is empty!")
    }
  }
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <ToastContainer />
      <Suspense fallback={<div></div>}>
        <section>
          <Header />
          {loading && <div className="loader d-block"></div>}
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a className="current">Doctor Dashboard List</a>
                </li>
              </ul>
            </div>
            <div className="head-hospMangmntList row mx-0">
              Doctor Dashboard List
              {siteName &&
              <div className='col-12 col-lg-2 col-md-2 cursor-pointer text-right ml-auto'>
                  <img src={csv} alt="PDFIcon" className='img-fluid ml-1' width="25" height="25" title="Download Excel" 
                  onClick={excelDownload}/>
              </div>  
              }
            </div>
            <div className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob
            justify-content-between" style={{ height: "auto" }}>
              <div className="row col-md-6 ml-0 pl-2">
                <DoctorDashboardSearch
                  siteName={siteName} setSiteName={setSiteName} setSiteId={setSiteId} setDoctorName={setDoctorName}/>
                <div className="d-flex col-md-6 dctrpanel-headinput mb-1 mb-md-0">
                  <input type="text" className="hspInfofieldinput w-75" disabled={siteName ? false : true} placeholder="Enter Doctor Name"
                  value={doctorName} 
                  onChange={(e) => { setDoctorName(e.target.value) }}
                  />
                  <button className="searchbtn-hspMng w-auto m-0 ml-3" type="button"
                    onClick={() => { getDoctorList() }} disabled={(siteName) ? false : true}>Search</button>
                </div>
              </div>
              <div className="row col-md-6 justify-content-end mt-0">
                <button className="searchbtn-hspMng w-auto mt-0" type="button"
                  onClick={() => { 
                    // setDoctorFileFlag(true) 
                    navigate('/admin/doctor-dashboard-file-list',
                      // {state:setFileData}
                    );
                  }}>
                  Show Files
                </button>
                <button className="searchbtn-hspMng w-auto mt-0" type="button"
                  onClick={() => { 
                    setExcelPopupFlag(true) 
                    setFileId("")
                  }}>
                  Excel Upload
                </button>
                <a className="searchbtn-hspMng w-auto text-white mt-0" href={DoctorTemplate} download="DoctorDashboardDetails.xlsx"
                  style={{ paddingTop: "7px" }}>
                  Download Template
                </a>
                {/* <button className="searchbtn-hspMng mb-md-auto m-0 w-auto" type="button"
                    onClick={fileSave}
                    disabled={uploaded ? false : true}>
                    Save
                  </button> */}
                {/* <div className="col-4 col-md-3 text-right">
                  <RiWhatsappFill size={24} className="theme-color mt-1 mr-xl-2" />
                  <MdEmail size={24} className="theme-color mt-1" />
                </div> */}
              </div>
            </div>
            <div className="row mx-0">

              {doctorList.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-bordered-hsp">
                    <thead>
                      <tr>
                        <th className="tablehead-hspmng" colSpan="2">
                          Doctor Id
                        </th>
                        <th className="tablehead-hspmng" colSpan="4">
                          Doctor Name{" "}
                        </th>
                        <th className="tablehead-hspmng" colSpan="3">
                          City
                        </th>
                        <th className="tablehead-hspmng" colSpan="4">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {doctorList.map((data) => (
                        <tr key={data.doctorId}>
                          <td colSpan="2">{data.doctorId}</td>
                          <td colSpan="4">{data.displayName}</td>
                          <td colSpan="3">{data.city}</td>
                          <td colSpan="4" className="nowrap d-flex aling-items-center flex-wrap">
                            <div>
                              <span className="ph_action_horizontal">
                                <a
                                  href="#"
                                  className="listLink-hspmangmnt"
                                  onClick={() => pdfDoctorDetails(data)}
                                >
                                  View Dashboard
                                </a>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="error-msg text-center w-100 mt-4">{showMessage}</p>
              )}
              {totalCount > 10 &&
                <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={max}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              }
            </div>
          </div>
          {doctorFileFlag &&
            <DoctorFilePopup setDoctorFileFlag={setDoctorFileFlag} setExcelPopupFlag={setExcelPopupFlag}  
            setFileId={setFileId} setFileData={setFileData}/>
          }
          {excelPopupFlag && <DoctorExcelPopup setExcelPopupFlag={setExcelPopupFlag} setFileId={setFileId} fileId={fileId}
           fileData={fileData} setFileData={setFileData}/>}
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
};

export default DoctorPanel;
